import {FilterItem} from '../models/schedule.model';
import {Class} from '../models/class.model';

export function createRange(start: number, end: number) {
    const items: number[] = [];
    for (let i = start; i <= end; i++) {
        items.push(i);
    }
    return items;
}

export function addDays(date: number | Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setTime(newDate.getTime() + days * 86400000);
    return newDate;
}

type RawEntittyCollectionItem =
    {
        id: string, name: string
    } | ({
        id: string, name: string
    }[]);

interface RawEntityCollection {
    coach?: RawEntittyCollectionItem;
    course?: RawEntittyCollectionItem;
    room?: RawEntittyCollectionItem;
    trainingCategories?: RawEntittyCollectionItem;
}

type CollectionItemType = 'coach' | 'group' | 'course' | 'room' | 'trainingCategories';

export function getUniqueItems(collection: RawEntityCollection[], itemType: CollectionItemType): FilterItem[] {
    return collection
        .map(i => i[itemType])
        .filter(i => !!i)
        .map(i => {
            if (Array.isArray(i)) {
                return i;
            } else {
                return [i];
            }
        })
        .reduce((accum, curr) => {
            accum.push(...curr);
            return accum;
        }, [])
        .filter(i => i.id)
        .reduce((uniqueItems, i) => {
            !uniqueItems.find(y => y.id === i.id) && uniqueItems.push({ ...i, ...{ isEnabled: true } });
            return uniqueItems;
        }, ([] as FilterItem[]));
}

export function getUniqueLevels(collection: RawEntityCollection[]): FilterItem[] {
    const name = 'level';
    const items: string[] = collection
        .map(x => x[name])
        .filter(x => !!x)
        .reduce((uniqueItems, i) => {
            !uniqueItems.find(x => x === i) && uniqueItems.push(i);
            return uniqueItems;
        }, ([] as string[]));
    return items.map((x, index) => ({id: '' + (index + 1), name: x, isEnabled: true}));
}

export function filterSchedule(
    value: Class[],
    groups: FilterItem[],
    courses: FilterItem[],
    coaches: FilterItem[],
    rooms: FilterItem[],
    trainingCategories: FilterItem[],
    levels: FilterItem[]
) {
    const enabledGroups = (groups || []).filter(x => x.isEnabled);
    const enabledCourses = (courses || []).filter(x => x.isEnabled);
    const enabledCoaches = (coaches || []).filter(x => x.isEnabled);
    const enabledRooms = (rooms || []).filter(x => x.isEnabled);
    const enabledTrainingCategories = (trainingCategories || []).filter(x => x.isEnabled);
    const enabledLevels = (levels || []).filter(x => x.isEnabled);

    return value
        .filter(i => !enabledGroups.length ||
            (i.groupName && enabledGroups
                .some(x => i.group.id === x.id)))

        .filter(i => !enabledCourses.length ||
            (i.course && enabledCourses
                .some(x => i.course.id === x.id)))

        .filter(i => !enabledCoaches.length ||
            (i.coach && enabledCoaches
                .some(x => i.coach.id === x.id)))

        .filter(i => !enabledRooms.length ||
            (i.room && enabledRooms
                .some(x => i.room.id === x.id)))

        .filter(i => !enabledTrainingCategories.length ||
            (i.trainingCategories && enabledTrainingCategories
                .some(category => i.trainingCategories
                    .some(x => x.id === category.id))))

        .filter(i => !enabledLevels.length ||
            (i.level && enabledLevels
                .some(x => i.level === x.name)));
}


export function guid(a: any): any {
    return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) :
        (1e7.toString(16) + -1e3 + -4e3 + -8e3 + -1e11)
            .replace(/[018]/g, guid)
            .replace(/-/g, '');
}

export function getNumEnding(num: number, for1: string, for4: string, for5: string) {
    let ending = for5;
    num = num % 100;
    if (num >= 11 && num <= 19) {
        ending = for5;
    } else {
        const i = num % 10;
        switch (i) {
            case 1:
                ending = for1;
                break;

            case 2:
            case 3:
            case 4:
                ending = for4;
                break;

            default:
                ending = for5;
        }
    }
    return ending;
}

export function filterItemComparer(left: FilterItem, right: FilterItem): number {
    if (left.name < right.name) {
        return -1;
    }

    if (left.name > right.name) {
        return 1;
    }

    return 0;
}

export function isIOS(): boolean {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window?.opera;
  // Проверка на iPhone, iPod, iPad
  // @ts-ignore
  return /iPad|iPhone|iPod/.test(userAgent) && !window?.MSStream;
}

export function isTelegramWebViewIOS(): boolean {
  // @ts-ignore
  return (typeof window?.TelegramWebviewProxy !== 'undefined') && (typeof window?.TelegramWebviewProxyProto !== 'undefined');
}

export function isTelegramWebViewAndroid(): boolean {
  // @ts-ignore
  return typeof window.TelegramWebview !== 'undefined';
}
