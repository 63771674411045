export const environment = {
    production: true,
    imageBaseUrl: 'https://media.ud4.ru/img',
    api: 'https://api.ud4.ru/v2/wg',
    widgetHost: 'https://wg.ud4.ru',
    widgetIdInApp: '0cc480b993a1452f962d9652838c8113',
    vkAuthAppId : '52812109',
    vkAppIds: {
        rooms: '6445829',
        schedule: '6277910',
        subscriptions: '6445819',
        courses: '6486550',
        coaches: '6486553'
    },
    vkAuthUrl: 'https://api.ud4.ru/v1/login-vk/',
    siteBaseUrl: 'https://sportpriority.ud4.ru/',
    offerUrl: 'https://sportpriority.ud4.ru/offer',
    politicsUrl: 'https://sportpriority.com/politika-obrabotki-personalnyh-dannyh/',
    paymentUrl: 'https://sportpriority.ud4.ru/pay-widget',
    paymentCertUrl: 'https://sportpriority.ud4.ru/pay-certwg',
    externalPaymentUrl: 'https://sportpriority.ud4.ru/pay-app/',
    externalPaymentSbpUrl: 'https://sportpriority.ud4.ru/pay-app-sbp/'
};
