import { IClubDictionaryData } from './../shared/models/club-dictionary-data.model';
import { Injectable, Injector, Optional, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, retry, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


import { environment } from '../../environments/environment';
import {
  GetWeekScheduleReq,
  GetClassReq,
  GetVirtualClassReq,
  CreateClassReq,
  RawScheduleResponse,
  IRequestRegularBookDetails,
  GetSubscriptionsPlanReq,
  SaveUserInfoReq
} from '../shared/models/schedule.model';
import { SP_ERROR_HANDLERS } from '../common/http-error-handlers';
import { SPErrorHandler } from '../shared/models/misc.models';
import { QueryStringParams, isVkParams, isWParams } from '../shared/models/parameters.model';
import { City } from '../shared/models/city.model';
import { PagedList } from '../shared/models/paged-list.model';
import { Club } from '../shared/models/club.model';
import { WidgetSettings } from '../shared/models/widget.model';
import { ApiResponse } from '../shared/models/common.models';
import { Room } from '../shared/models/room.model';
import { Course } from '../shared/models/course.model';
import { SubscriptionPlan } from '../shared/models/subscriptions.model';
import { Coach } from '../shared/models/coach.model';
import { Class } from '../shared/models/class.model';
import { ILoginData, IToken, IUserInfo, BookingRes } from '../shared/models/profile.model';
import { StorageService, AppSettings } from './storage.service';
import { ClubInfo, ICertificate } from '../shared/models/certificates.model';
import { ClubDownTimeGetByClubReq } from '../shared/models/club-down-time.model';
import { IRegisterData } from '../shared/models/register-data.model';
import { IVKUserInfo } from 'app/shared/models/vk.interfaces';
import {IVKAccessTokeInfo, IVKAccessTokenRes} from './vk-auth.service';

@Injectable()
export class ApiService {
  private apiBaseUrl: string = environment.api;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    @Optional() @Inject(SP_ERROR_HANDLERS) private additionalErrorHandlers: SPErrorHandler[]
  ) { }

  public readonly cities = ((api: this) => ({
    get(id: string, useDefaultErrorHandling = true) {
      return api.post<City>('common-getCity', { id }, useDefaultErrorHandling);
    }
  }))(this);

  public readonly search = ((api: this) => {
    return {
      clubs(text: string, useDefaultErrorHandling = true): Observable<ApiResponse<PagedList<Club>>> {
        return api.post<PagedList<Club>>('common-searchClubs', { text }, useDefaultErrorHandling);
      }
    };
  })(this);

  public readonly common = ((api: this) => {
    return {
      getBranches(clubId: string, useDefaultErrorHandling = true): any {
        return api.get('get-branches', { clubId: clubId }, useDefaultErrorHandling);
      },
      getClubRefs(clubId: string) {
        return api.get<{ roomToCourses: { id: string, ids: string[] }[], courseToCoaches: { id: string, ids: string[] }[], courseToSubsplans: { id: string, ids: string[] }[] }>('get-clubrefs', { clubId: clubId });
      },
      calculateSubscriptionCost(subVariantId: string) {
        return api.post('user-calculateSubsCost', { id: subVariantId });
      },
    };
  })(this);

  public readonly settings = ((api: this) => ({
    getByWidgetId(req: QueryStringParams, useDefaultErrorHandling = true) {
      if (isVkParams(req)) {
        return api.post<WidgetSettings>('common-getSettings', { groupid: req.group_id, appid: req.api_id }, useDefaultErrorHandling);
      } else if (isWParams(req)) {
        return api.post<WidgetSettings>('common-getSettings', { id: req.w, clubId: req.clubId }, useDefaultErrorHandling);
      }
    }
  }))(this);

  public readonly clubSettings = ((api: this) => {
    return {
      getByClub(_id: string, useDefaultErrorHandling = true): any {
        return api.get('../../wg/clubSettings-getByClub', { id: _id }, useDefaultErrorHandling);
      },
      checkAllowBookingsOnlyClubClients(clubId: string, userId: string, useDefaultErrorHandling = true): any {
        return api.get('../../wg/clubSettings-CheckAllowBookingsOnlyClubClients', { clubId, userId }, useDefaultErrorHandling);
      }
    };
  })(this);

  public clubDictionaries = ((api: this) => {
    return {
      get(clubId: string, alias: string) {
        return api.apiPost<IClubDictionaryData>('../../v1/clubDictionary-get', { clubId: clubId, alias: alias });
      },
      save(dic: IClubDictionaryData) {
        return api.apiPost<IClubDictionaryData>('../../v1/clubDictionary-save', dic);
      },
      remove(dic: IClubDictionaryData) {
        return api.apiPost<IClubDictionaryData>('../../v1/clubDictionary-remove', dic);
      },
      getByClub(clubId: string) {
        return api.apiPost<Array<IClubDictionaryData>>(`../../v1/clubDictionaryItems-getByClub`, { clubId });
      },
      setIsDefault(dic: IClubDictionaryData) {
        return api.apiPost<Array<IClubDictionaryData>>(`../../v1/clubDictionary-setIsDefault`, dic);
      },
      getIsDefault(clubId: string) {
        return api.apiPost<IClubDictionaryData>(`../../v1/clubDictionary-getIsDefault`, { clubId });
      },
    };
  })(this);

  public readonly payment = ((api: this) => ({

  }))(this);

  public readonly subscriptionPlans = ((api: this) => ({
    getByCourse(req: GetSubscriptionsPlanReq) {
      return api.apiPost<Array<SubscriptionPlan>>('subscriptionPlans-getByCourse', req);
    },
    getByClub(id: string, useDefaultErrorHandling = true) {
      return api.post<Array<SubscriptionPlan>>('subscriptionPlans-getByClub', { id }, useDefaultErrorHandling);
    },
    get(id: string, useDefaultErrorHandling = true) {
      return api.post<SubscriptionPlan>('subscriptionPlans-get', { id }, useDefaultErrorHandling);
    },
    clientSubscriptionLimit(userId: string, subscriptionPlanVariantId: string, useDefaultErrorHandling = true) {
      return api.post<any>('clientSubscriptionLimit', { userId: userId, subscriptionPlanVariantId: subscriptionPlanVariantId }, useDefaultErrorHandling);
    },
    clientSubscriptionGetByUser(clubId: string, userId: string) {
      return api.apiPost<Array<any>>('clientSubscription-getByUser', { clubId: clubId, userId: userId });
    }
  }))(this);

  public readonly courses = ((api: this) => ({
    getByClub(id: string, useDefaultErrorHandling = true) {
      return api.post<Array<Course>>('courses-getByClub', { id }, useDefaultErrorHandling);
    },
    get(id: string, useDefaultErrorHandling = true) {
      return api.post<Course>('courses-get', { id }, useDefaultErrorHandling);
    }
  }))(this);

  public readonly rooms = ((api: this) => ({
    getByClub(id: string, useDefaultErrorHandling = true) {
      return api.post<Array<Room>>('rooms-getByClub', { id }, useDefaultErrorHandling);
    },
    get(id: string, useDefaultErrorHandling = true) {
      return api.post<Room>('rooms-get', { id }, useDefaultErrorHandling);
    }
  }))(this);

  public readonly coaches = ((api: this) => ({
    getByClub(id: string, useDefaultErrorHandling = true) {
      return api.post<Array<Coach>>('coaches-getByClub', { id }, useDefaultErrorHandling);
    },
    get(id: string, useDefaultErrorHandling = true) {
      return api.post<Coach>('coaches-get', { id }, useDefaultErrorHandling);
    }
  }))(this);

  public readonly schedule = ((api: this) => ({
    createClassRequest(req: CreateClassReq, useDefaultErrorHandling = true) {
      return api.post('schedule-createClassRequest', { model: req }, useDefaultErrorHandling);
    },

    getClubSchedule(req: GetWeekScheduleReq, useDefaultErrorHandling = true) {
      return api.post<RawScheduleResponse>('schedule-getWeek', req, useDefaultErrorHandling);
    },

    getNearFuture(req: GetWeekScheduleReq, useDefaultErrorHandling = true) {
      return api.post<RawScheduleResponse>('schedule-nearFuture', req, useDefaultErrorHandling);
    },

    getVirtualClass(req: GetVirtualClassReq, useDefaultErrorHandling = true) {
      return api.post<Class>('schedule-getVirtualClass', req, useDefaultErrorHandling);
    },

    getClass(req: GetClassReq) {
      return api.apiPost<Class>('schedule-getClass', req);
    },

    saveUserInfo(req: SaveUserInfoReq, useDefaultErrorHandling = true) {
      return api.apiPost<any>('schedule-saveUserInfo', req);
    },
  }))(this);

  public readonly clubDownTime = ((api: this) => ({
    getClubDownTimeByClubId(req: ClubDownTimeGetByClubReq) {
      return api.post('ClubDownTimeGetByClubId', req, true);
    }
  }))(this);

  public readonly certificates = ((api: this) => ({
    getList(_type: number, _clubId: string, useDefaultErrorHandling = true) {
      return api.get('../../v1/app/certificatesUmbraco-getAll', { type: _type, clubId: _clubId }, useDefaultErrorHandling);
    },
    generateNewCertificate(model: ICertificate, useDefaultErrorHandling = true) {
      return api.post<any>('../../v1/app/certificate/generate', model, useDefaultErrorHandling);
    },
    generateNewCertificateSync(model: ICertificate, useDefaultErrorHandling = true) {
      return api.postSync('../../v1/app/certificate/generate', model, useDefaultErrorHandling);
    },
    getInfoByIds(ids: string[], useDefaultErrorHandling = true) {
      return api.post<ClubInfo[]>('club-getInfoByIds', ids, useDefaultErrorHandling);
    }
  }))(this);

  public readonly club = ((api: this) => ({
    getInfoByIds(ids: string[], useDefaultErrorHandling = true) {
      return api.post<ClubInfo[]>('club-getInfoByIds', ids, useDefaultErrorHandling);
    }
  }))(this);

  private handleErrors = ((api: this) =>
  ((error: HttpErrorResponse) => {
    if (api.additionalErrorHandlers) {
      api.additionalErrorHandlers.forEach(handler => handler.handle(error));
    }
    return this.mapHttpErrorToApiResponse(error);
  })
  )(this);


  public readonly userAccounts = ((api: this) => ({
    login(loginData: ILoginData, useDefaultErrorHandling = true) {
      return api.apiPost<IToken>('account/login', loginData);
    },
    getProfile() {
      return api.apiPost<IUserInfo>('context-getUserInfo', {});
    },
    loginFirebase(accessToken: any) {
      return api.apiPost<IToken>('account/login-firebase', accessToken);
    },
    beginPhoneRegistration(phone: string, isCallCode: boolean) {
      return api.apiPost(`../../v1/account/begin-phone-registration`, { phone: phone, isCallCode: isCallCode });
    },
    completePhoneRegistration(authToken: string, confirmationToken: string) {
      return api.apiPost<IToken>(`../../v1/account/complete-phone-registration`, { authToken, confirmationToken });
    },
    beginEmailRegistration(email: string) {
      return api.apiPost(`../../v1/account/begin-email-registration`, { email: email });
    },
    completeEmailRegistration(authToken: string, confirmationToken: string) {
      return api.apiPost(`../../v1/account/complete-email-registration`, { authToken, confirmationToken });
    },
    beginPushLogin(emailOrPhone: string) {
      return api.apiPost(`../../v1/account/begin-push-login`, { emailOrPhone });
    },
    completePushLogin(authToken: string, confirmationToken: string) {
      return api.apiPost(`../../v1/account/complete-push-login`, { authToken, confirmationToken });
    },
    isDuplicateEmail(email: string) {
      return api.apiPost('../../v2/wg/account/is-duplicate-email', { email: email });
    },
    isDuplicatePhone(phone: string) {
      return api.apiPost('../../v1/account/is-duplicate-phone', { phone: phone });
    },
    register(registerData: IRegisterData) {
      return api.apiPost('../../v1/account/register', registerData);
    },
    registerEmailAndPhone(registerData: IRegisterData) {
      return api.apiPost('../../v1/account/register-email-and-phone', registerData);
    },
    beginResetPassword(emailOrPhone: string, isSendNotification: boolean = false) {
      return api.apiPost(`../../v1/account/begin-reset-password`, { emailOrPhone, isSendNotification, isSpApplication: true });
    },
    confirmRecoveryPassword(authToken: string, confirmationToken: string) {
      return api.apiPost(`../../v1/account/confirm-recovery-password`, { authToken, confirmationToken });
    },
    completeRecoveryPassword(authToken: string, confirmationToken: string, newPassword: string) {
      return api.apiPost(`../../v1/account/complete-recovery-password`, { authToken, confirmationToken, newPassword });
    },
    loginVk(vkUserInfo: IVKUserInfo) {
      return api.post<IToken>('../../v1/app/account-vkontakte/login', vkUserInfo).pipe(
        map(response => response as unknown as IToken)
      );
    },
    getVkAccessToken(vKAccessTokeInfo: IVKAccessTokeInfo) {
      return api.post<IToken>('../../v1/app/account-vkontakte/get-access_token', vKAccessTokeInfo).pipe(
        map(response => response as unknown as IVKAccessTokenRes)
      );
    },
  }))(this);

  public readonly userSchedule = ((api: this) => ({
    bookRegular(request: IRequestRegularBookDetails) {
      return api.apiPost<BookingRes>(`classvisits-bookregular`,
        request);
    },
    checkingBooking(request: IRequestRegularBookDetails) {
      return api.apiPost<BookingRes>(`classvisits-checkingBooking`,
        request);
    },
  }))(this);

  public setCulture(culture: string): void {
    this.culture = culture;
  }

  private culture: string;

  private mapHttpErrorToApiResponse<T>(error: HttpErrorResponse) {
    const res: ApiResponse<any> = {
      confirmEmail: false, confirmPhone: false,
      desc: (error.error && error.error.desc) ||
        (error.status === 0
          ? 'Произошла сетевая ошибка. Проверьте, что подключение к интернету работает стабильно.'
          : error.message),
      result: null,
      error
    };
    return of(res);
  }

  private post<T>(url: string, params: { [key: string]: any }, useDefaultErrorHandling = true) {
    const fullUrl = this.makeUrl(url);
    const request = this.http.post<ApiResponse<T>>(fullUrl, params, { headers: this.makeRequestOptionArgs() });

    return useDefaultErrorHandling
      ? request.pipe(
        catchError(this.handleErrors)
      )
      : request.pipe(
        catchError((error: HttpErrorResponse) => this.mapHttpErrorToApiResponse(error))
      );
  }

  private get<T>(url: string, params: { [key: string]: any }, useDefaultErrorHandling = true) {
    const fullUrl = this.makeUrl(url);
    const request = this.http.get<ApiResponse<T>>(fullUrl, { params: params });

    return useDefaultErrorHandling
      ? request.pipe(
        catchError(this.handleErrors)
      )
      : request.pipe(
        catchError((error: HttpErrorResponse) => this.mapHttpErrorToApiResponse(error))
      );
  }

  private apiPost<T>(url: string, params: { [key: string]: any }) {
    const fullUrl = this.makeUrl(url);
    const request = this.http.post<ApiResponse<T>>(fullUrl, params, { headers: this.makeRequestOptionArgs() });
    return request;
  }

  private postSync(url: string, params: { [key: string]: any }, useDefaultErrorHandling = true) {
    const fullUrl = this.makeUrl(url);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', fullUrl, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    try {
      const token = this.storageService.read(AppSettings.token) ?? this.getCookie(AppSettings.token);
      if (token) xhr.setRequestHeader('Authorization', `Bearer ${token.accessToken}`);
    } catch { }
    try {
      xhr.send(JSON.stringify(params));
      if (xhr.status === 200) {
        return JSON.parse(xhr.responseText);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (useDefaultErrorHandling) {
        const res: HttpErrorResponse = {
          name: err.name,
          error: err.message,
          message: err.message,
          status: xhr.status,
          statusText: xhr.statusText,
          ok: false, headers: null, type: null, url: null
        };
        this.handleErrors(res);
      }
      throw err;
    }
  }

  getCookie(name) {
    var cookie = " " + document.cookie;
    var search = " " + name + "=";
    var setStr = null;
    var offset = 0;
    var end = 0;
    if (cookie.length > 0) {
      offset = cookie.indexOf(search);
      if (offset != -1) {
        offset += search.length;
        end = cookie.indexOf(";", offset)
        if (end == -1) {
          end = cookie.length;
        }
        setStr = unescape(cookie.substring(offset, end));
      }
    }
    if (setStr)
      return JSON.parse(setStr);
  }

  private makeUrl(url: string) {
    return `${this.apiBaseUrl}/${url}`;
  }

  private makeRequestOptionArgs() {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');
    if (this.culture)
      httpHeaders = httpHeaders.set('Accept-Language', this.culture);

    // Чтобы работало в инкогнито /gIGRZ033/
    try {
      let token = this.storageService.read(AppSettings.token);

      if (!token)
        token = this.getCookie(AppSettings.token);

      if (token)
        httpHeaders = httpHeaders.set('Authorization', `Bearer ${token.accessToken}`);
    } catch { }

    return httpHeaders;
  }
}


// https://vk.com/add_community_app?aid=6445829&callback_url=http://sportpriorityapi.ud4.ru/v2/wg/v2/wg/vk-bind-group/93803909
