import { Injectable } from '@angular/core';
import { IToken, ILoginData, IUserProfile, IUserInfo } from '../shared/models/profile.model';
import { ApiService } from './api.service';
import { StorageService, AppSettings } from './storage.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Auth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, getIdToken } from '@angular/fire/auth';
import { EventsService } from './events.service';
import { VkAuthWrap } from '../shared/classes/vk-auth';
import { filter, take } from 'rxjs/operators';
import { ScheduleWidgetSettings } from '../shared/models/widget.model';
import moment from 'moment-mini-ts';
import { SettingsService } from './settings.service';

@Injectable()
export class ProfileService {
  constructor(
    private api: ApiService,
    private storageService: StorageService,
    private auth: Auth,
    public events: EventsService,
    private settingsService: SettingsService,
    private vkAuth: VkAuthWrap
  ) { }

  //public userProfile: BehaviorSubject<IUserProfile> = new BehaviorSubject<IUserProfile>(null);
  public userInfo: BehaviorSubject<IUserInfo> = new BehaviorSubject<IUserInfo>(null);
  public isApp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public isMiniApp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public isToken: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  getUserProfile() {
    this.api.userAccounts.getProfile().subscribe(data => {
      if (data) {
        this.userInfo.next(data.result as IUserInfo);
      }
    });
  }

  public login(loginData: ILoginData) {
    return this.api.userAccounts.login(loginData);
  }

  public logout() {
    localStorage.removeItem(AppSettings.token);
    this.userInfo.next(null);
  }

  public async writeToken(token) {
    token ?
      this.storageService.write(AppSettings.token, token) :
      this.storageService.clear(AppSettings.token);

    await this.settingsService.settings.pipe(
      filter<ScheduleWidgetSettings>(Boolean),
      take(1)
    ).subscribe(
      async settings => {
        parent.postMessage({ yandexEvent: { event: 'auth', widgetId: settings.widgetId, data: { clubId: settings.clubId } }, top: 1, extra: true }, '*');
      });

    // this.setCookieToken(token);
  }

  public readToken() {
    let token: IToken = this.storageService.read(AppSettings.token);

    if (token !== null && token.expiresAt < (new Date().getTime())) {
      token = null;
      this.writeToken(null);
    }

    return token;
  }

  public async logInGp() {
    try {
      await this.authGp();
      await this.doFirebaseAuth();

    } catch (err) {
      alert(err);
    }
  }

  setIsApp(bool: boolean){
    this.isApp.next(bool);
  }

  private async authGp() {
    return await signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  private async doFirebaseAuth() {
    const instId = this.storageService.read(AppSettings.instId);
    const token = await getIdToken(this.auth.currentUser, true);
    this.api.userAccounts.loginFirebase({ accessToken: token, instId }).subscribe((res) => {
      this.writeToken(res);
      this.getUserProfile();
    });
  }
  private loginVkSubscription: Subscription = null;

  public async logInVk(res: IToken) {

    if (res && (res as IToken).accessToken) {
      const instId = this.storageService.read(AppSettings.instId);
      const accessToken = (res as IToken).accessToken;

      this.writeToken(res);
      this.getUserProfile();

      // this.api.userAccounts.loginFirebase({ accessToken: accessToken, instId }).subscribe((res) => {
      //   console.log('res', res)
      //   this.writeToken(res);
      //   this.getUserProfile();
      // });
    }
  }

  public async logInFb() {
    try {
      await this.authFb();
      const token = await this.doFirebaseAuth();
    } catch (err) {
      alert(err);
    }
  }

  private async authFb() {
    return await signInWithPopup(this.auth, new FacebookAuthProvider());
  }

  setCookieToken(token: IToken) {
    let cookieToken = "Token=" + JSON.stringify(token);

    if (document.location.href.indexOf("ud4.ru") > 0)
      cookieToken += ";domain=.ud4.ru;path=/;"

    if (document.location.href.indexOf("sportpriority.com") > 0)
      cookieToken += ";domain=.sportpriority.com;path=/;"

    document.cookie = cookieToken;
  }

  getCookieToken() {
    const token = JSON.parse(document.cookie);
  }

  clearCookie() {
    if (document.location.href.indexOf("ud4.ru") > 0)
      this.clearCookieTrue('Token', '.ud4.ru', '/')

    else if (document.location.href.indexOf("sportpriority.com") > 0)
      this.clearCookieTrue('Token', '.sportpriority.com', '/')

    else this.clearCookieTrue('Token', '', '/')
  }

  clearCookieTrue(name, domain, path) {
    var domain = domain || document.domain;
    var path = path || "/";
    document.cookie = name + "=; expires=" + +new Date + "; domain=" + domain + "; path=" + path;
  }

  public async completePhoneRegistration(authToken: string, confirmationToken: string) {
    this.api.userAccounts.completePhoneRegistration(authToken, confirmationToken).subscribe(res => {
      console.log('res: ', res)
      this.writeToken(res);
      this.getUserProfile();
    });
  }
}
