import { Observable, Subscription } from 'rxjs';
import { Injectable, Injector } from '@angular/core';

import { merge } from 'rxjs/operators';
import { EventsService } from 'app/services/events.service';
import { environment } from 'environments/environment';
import { IToken } from '../models/profile.model';


@Injectable()
export class VkAuthWrap {
    public authUrl : string;
    private wnd: Window;
    private subscription: Subscription = null;
    private isApp: boolean;

    constructor(
        private events: EventsService,
        injector: Injector,
    ) {
        //this.authUrl = environment.vkAuthUrl;
    }

    private eventHandlerWrap = (event: any) => {
        this.eventHandler(event);
    }

    public showPage(wnd: Window) {
        const url = this.authUrl;
      console.log(url)
            window.addEventListener('message', this.eventHandlerWrap);
            this.wnd = wnd;
            this.wnd.location.href = url;
    }

    private eventHandler(event: any) {
        if (this.isApp) {
            switch (event.type) {
                case 'loadstop': {

                    const url = (event.url && typeof event.url === 'string') ? event.url as string : '';

                    url.includes('login-vk-fail') && this.finalize(false);
                    url.includes('login-vk-success') && this.finalize(url.split('?token=')[1]);
                    break;
                }
                case 'exit': {
                    this.finalize(null);
                    break;
                }
                case 'loaderror': {
                    this.finalize(false);
                    break;
                }
            }
        } else {
            const data = (event.data && typeof event.data === 'object') ? event.data : null;
            if (data && data.url) {
                data.url.includes('login-vk-fail') && this.finalize(false);
                data.url.includes('login-vk-success') && this.finalize(data.token);
            }
        }
    }

    private finalize(token: string | IToken | boolean) {
        if (this.isApp) {
            this.subscription?.unsubscribe();
        } else {
            this.wnd && this.wnd.close();
            window.removeEventListener('message', this.eventHandlerWrap);
        }

        let result = token;
        token && typeof token === 'string' && (result = JSON.parse(decodeURIComponent(token)));
        this.events.publishNew('login-vk', result);
    }
}
